html,
body,
#root {
  margin: 0;
  height: 100%;
  overflow-y: hidden;
}

main {
  margin-top: -30px;
}
